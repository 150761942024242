// assets
import { IconBuildingEstate, IconNews, IconChecklist, IconCategory, IconUserCheck, IconPeace, IconAddressBook, IconUsers, IconUserPlus, IconHelp, IconSitemap, } from '@tabler/icons';
import { StudentsList, overview, InstructorList, path, partner, report } from '../assets/images/icons/studentlist';


// constant
const icons = {
    IconBrandChrome: IconUsers,
    IconAddUser: IconUserPlus,
    IconCohort: IconAddressBook,
    IconChapter: IconPeace,
    IconPermission: IconUserCheck,
    IconSubmission: IconChecklist,
    IconCategory: IconCategory,
    IconNews: IconNews,
    IconHelp: IconHelp,
    IconBuildingEstate: IconBuildingEstate,
    IconSitemap: IconSitemap,
    path: path,

};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//


export const instructor = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Overview',
            type: 'item',
            url: '/dashboard/default',
            icon: overview,
            breadcrumbs: false
        },
        {
            id: 'user',
            type: 'collapse',
            icon: icons['IconNews'],
            title: "Pending Assignments to be corrected",
            type: 'item',
            url: '/assignment-path-list',
            breadcrumbs: false
        },
        {
            id: 'permissions',
            type: 'collapse',
            icon: StudentsList,
            title: 'Students List',
            type: 'item',
            url: '/instructor-students',
            breadcrumbs: false
        },
        {
            id: 'Path',
            type: 'collapse',
            icon: icons['path'],
            title: 'Certificate(s) list',
            type: 'item',
            url: '/instructor-paths',
            breadcrumbs: false
        },
    ]
};
