const config = {
  basename: '',
  defaultPath: '/dashboard/default',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  variable: [],

  // API_SERVER: 'http://localhost:3000/api/v1/',
  // BASE_API: 'http://localhost:3000',

  // API_SERVER: 'https://dfvKcyGJhx.academy-int.com/api/v1/',
  // BASE_API: 'https://dfvKcyGJhx.academy-int.com',

  API_SERVER: 'https://backlanterns.lezarts.digital/api/v1/',
  BASE_API: 'https://backlanterns.lezarts.digital',
};

export default config;
