import React from 'react';
import config from '../../../../config';
// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@material-ui/core';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from './../../../../assets/images/users/user-round.svg';

// style constant
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '330px',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px'
        }
    },
    listAction: {
        top: '22px'
    },
    actionColor: {
        color: theme.palette.grey[500]
    },

    listItem: {
        padding: 0
    },
    sendIcon: {
        marginLeft: '8px',
        marginTop: '-3px'
    },
    listDivider: {
        marginTop: 0,
        marginBottom: 0
    },
    listChipError: {
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        height: '24px',
        padding: '0 6px',
        marginRight: '5px'
    },
    listChipWarning: {
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light,
        height: '24px',
        padding: '0 6px'
    },
    listChipSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: '24px',
        padding: '0 6px'
    },
    listAvatarSuccess: {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        border: 'none',
        borderColor: theme.palette.success.main
    },
    listAvatarPrimary: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
        border: 'none',
        borderColor: theme.palette.primary.main
    },
    listContainer: {
        paddingLeft: '56px'
    },
    uploadCard: {
        backgroundColor: theme.palette.secondary.light
    },
    paddingBottom: {
        paddingBottom: '16px',
        minWidth: '200px'
    },
    itemAction: {
        cursor: 'pointer',
        padding: '16px',
        '&:hover': {
            background: theme.palette.primary.light
        }
    }
}));

//-----------------------|| NOTIFICATION LIST ITEM ||-----------------------//

const NotificationList = ({ Notifications }) => {
    const classes = useStyles();
    function timeAgo(timestampStr) {
        const timestamp = new Date(timestampStr);
        const now = new Date();
        const secondsPast = (now.getTime() - timestamp.getTime()) / 1000;
        if (secondsPast < 60) {
            return parseInt(secondsPast) + 's ago';
        }
        if (secondsPast < 3600) {
            return parseInt(secondsPast / 60) + 'm ago';
        }
        if (secondsPast <= 86400) {
            return parseInt(secondsPast / 3600) + 'h ago';
        }
        if (secondsPast > 86400) {
            const day = timestamp.getDate();
            const month = timestamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
            const year = timestamp.getFullYear() == now.getFullYear() ? "" : " " + timestamp.getFullYear();
            return day + " " + month + year;
        }
    }

    return (
        <List className={classes.navContainer}>
            {Notifications.map((e, i) => {
                return (
                    <>
                        <div className={classes.itemAction}>
                            <ListItem alignItems="center" className={classes.listItem}>
                                <ListItemAvatar>
                                    <Avatar alt="John Doe" src={e?.sender?.photo ? config.BASE_API + e?.sender?.photo : 'https://static.vecteezy.com/system/resources/thumbnails/004/141/669/small/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">{e?.sender?.first_name + ' ' + e?.sender?.last_name}</Typography>} />
                                <ListItemSecondaryAction className={classes.listAction}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12}>
                                            <Typography variant="caption" display="block" gutterBottom className={classes.actionColor}>
                                                {timeAgo(e?.createdAt)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Grid container direction="column" className={classes.listContainer}>
                                <Grid item xs={12} className={classes.paddingBottom}>
                                    <Typography variant="subtitle2">{e?.content}</Typography>
                                </Grid>
                              
                            </Grid>
                        </div>
                        <Divider className={classes.listDivider} />
                    </>
                )
            })
            }

        </List>
    );
};

export default NotificationList;