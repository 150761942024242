// assets
import { IconBuildingEstate, IconCategory2, IconChecklist, IconCategory, IconUserCheck, IconPeace,IconNews, IconAddressBook, IconUsers, IconUserPlus, IconHelp, IconSitemap,IconTag,IconArrowsTransferUp ,IconSpeakerphone,IconQuestionCircle} from '@tabler/icons';
import { StudentsList, overview, InstructorList, path, partner, report } from '../assets/images/icons/studentlist';


// constant
const icons = {
    IconBrandChrome: IconUsers,
    IconAddUser: IconUserPlus,
    IconCohort: IconAddressBook,
    IconChapter: IconPeace,
    IconPermission: IconUserCheck,
    IconSubmission: IconChecklist,
    IconCategory: IconCategory,
    IconNews: IconTag,
    IconHelp: IconHelp,
    IconBuildingEstate: IconBuildingEstate,
    IconSitemap: IconSitemap,
    IconArrowsTransferUp: IconArrowsTransferUp,
    path: path,
    IconSpeakerphone: IconSpeakerphone,
    assignment: IconNews,
    Questions: IconQuestionCircle,

};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//



export const superadmin = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Overview',
            type: 'item',
            url: '/dashboard/default',
            icon: overview,
            breadcrumbs: false
        },
        {
            id: 'Group',
            type: 'collapse',
            icon: icons['IconSubmission'],
            title: 'Path(s) list',
            type: 'item',
            url: '/admin-groups',
            breadcrumbs: false
        },
        {
            id: 'Path',
            type: 'collapse',
            icon: icons['path'],
            title: 'Certificate(s) list',
            type: 'item',
            url: '/admin-paths',
            breadcrumbs: false
        },
    
        {
            id: 'Session',
            type: 'collapse',
            icon: icons['IconSitemap'],
            title: 'Session list',
            type: 'item',
            url: '/admin-session-list',
            breadcrumbs: false
        },
        {
            id: 'Admin',
            type: 'collapse',
            icon: icons['IconBrandChrome'],
            title: 'Admin List',
            type: 'item',
            url: '/admins',
            breadcrumbs: false
        },
        {
            id: 'user',
            type: 'collapse',
            icon: InstructorList,
            title: 'Instructor(s) list',
            type: 'item',
            url: '/admin-instructors',
            breadcrumbs: false
        },
        {
            id: 'permissions',
            type: 'collapse',
            icon: StudentsList,
            title: 'Student(s) List',
            type: 'item',
            url: '/admin-students',
            breadcrumbs: false
        },
        {
            id: 'Partners',
            type: 'collapse',
            icon: partner,
            title: 'Partner(s) List',
            type: 'item',
            url: '/partners',
            breadcrumbs: false
        },
   
        {
            id: 'Category',
            type: 'collapse',
            icon: icons['IconNews'],
            title: 'Tag(s) list',
            type: 'item',
            url: '/admin-tag-list',
            breadcrumbs: false
        },
        {
            id: 'Report',
            type: 'collapse',
            icon: report,
            title: 'Problem Report list',
            type: 'item',
            url: '/admin-reports',
            breadcrumbs: false
        },
     
        {
            id: 'PreSelectionList',
            type: 'collapse',
            icon: icons['IconCategory'],
            title: 'Pre-Selection',
            type: 'item',
            url: '/pre-selection-path-list',
            breadcrumbs: true

        },
        {
            id: 'Transaction',
            type: 'collapse',
            icon: icons['IconArrowsTransferUp'],
            title: 'Transaction(s)',
            type: 'item',
            url: '/transactions',
            breadcrumbs: false

        },
        {
            id: 'PendingAssignments',
            type: 'collapse',
            icon: icons['assignment'],
            title: "Pending Assignments to be corrected",
            type: 'item',
            url: '/assignment-path-list',
            breadcrumbs: false
        },
      
        {
            id: 'Questions',
            type: 'collapse',
            icon: icons['Questions'],
            title: "Questions",
            type: 'item',
            url: '/questions',
            breadcrumbs: false
        },
    ]

};
