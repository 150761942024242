// assets
import { IconKey, IconReceipt2, IconBug, IconBellRinging, IconPhoneCall } from '@tabler/icons';

// constant
const icons = {
    IconKey: IconKey,
    IconReceipt2: IconReceipt2,
    IconBug: IconBug,
    IconBellRinging: IconBellRinging,
    IconPhoneCall: IconPhoneCall
};

//-----------------------|| EXTRA PAGES MENU ITEMS ||-----------------------//

export const pages = {
    id: 'pages',
    title: 'Other',
    type: 'group',
    children: [
        {
            id: 'honor code',
            type: 'collapse',
            icon: icons['IconCategory'],
            title: 'Honor code',
            type: 'item',
            url: '/honor-code',
            breadcrumbs: false

        },
        {
            id: 'terms and conditions',
            type: 'collapse',
            icon: icons['IconCategory'],
            title: 'Terms and conditions',
            type: 'item',
            url: '/terms-and-conditions',
            breadcrumbs: false

        },
        {
            id: 'cookie policy',
            type: 'collapse',
            icon: icons['IconCategory'],
            title: 'Cookie policy',
            type: 'item',
            url: '/cookie-policy',
            breadcrumbs: false

        },
        {
            id: 'privacy policy',
            type: 'collapse',
            icon: icons['IconCategory'],
            title: 'Privacy policy',
            type: 'item',
            url: '/privacy-policy',
            breadcrumbs: false

        },
        {
            id: 'announcement',
            type: 'collapse',
            icon: icons['IconSpeakerphone'],
            title: "Announcement",
            type: 'item',
            url: '/announcement',
            breadcrumbs: false
        },

    ]
};
