import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items';
import menuItemssuperadmin from './../../../../menu-items/indexsuperadmin';
import menuItemsadmin from './../../../../menu-items/indexadmin';
import menuItemsinstructor from './../../../../menu-items/indexinstrauctor';
import { useSelector } from 'react-redux';

const MenuList = () => {
    // const customization = useSelector((state) => state.customization);
    const customizations = useSelector((state) => state?.account?.role);
    let views = customizations == 4 ? menuItemssuperadmin : customizations == 1 ? menuItemsadmin : menuItemsinstructor
    //const [loading, setLoading] = useState(true);
    // const [menuItems, setMenuItems] = useState([]);

    // useEffect(() => {
    //     filterMenuItems();
    // }, [customization.variable]);

    // const filterMenuItems = () => {
    //     try {
    //         const storedVariable = localStorage.getItem('Lantern-account');
    //         const data = JSON.parse(storedVariable);
    //         let permissions;
    //         if (customization.variable.length > 0) {
    //             permissions = customization.variable;
    //         } else {
    //             permissions = data.perm;
    //         }
    //         const filteredSecondArray = menuItem.items[1]?.children?.filter(child => {
    //             const permissionNeeded = `${child.id}_get_all`;
    //             return permissions.includes(permissionNeeded);
    //         });


    //         // Update menu items state with the filtered second array
    //         setMenuItems([
    //             menuItem.items[0],
    //             { ...menuItem.items[1], children: filteredSecondArray || [] },
    //             menuItem.items[2],
    //         ]);
    //     } catch (error) {
    //         console.log('Error filtering menu items:', error);
    //     } finally {
    //         setLoading(false); // Set loading state to false regardless of try/catch outcome
    //     }
    // };

    const renderMenuItems = () => {
        // if (loading) {
        //     return (
        //         <Typography variant="h6" color="error" align="center">
        //             Loading...
        //         </Typography>
        //     );
        // } else {
        return views.items.map(item => {
            switch (item.type) {
                case 'group':
                    return  <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        // }
    };

    return renderMenuItems();
};

export default MenuList;
