import React from 'react';
import img from '../assets/images/logo/Academy_Interactive_-_Logo_Final_2023_-_BBlack-04.png'
// material-ui
import { useTheme } from '@material-ui/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = ({width,height}) => {
    const theme = useTheme();

    return (
       <img src={img} style={{height:width,width:height}}/>
    );
};

export default Logo;
