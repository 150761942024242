import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));
const ConfirmEmail = Loadable(lazy(() => import('../views/pages/reset-password/confirm-email')));
const resetPassword = Loadable(lazy(() => import('../views/pages/reset-password/reset-password')));
const UsedLink = Loadable(lazy(() => import('../ui-component/usedLink')));

//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/login', '/confirm-email', '/reset-password','/oops']}>
            {/* <MinimalLayout> */}
            <Switch location={location} key={location.pathname}>
                <NavMotion>
                    <GuestGuard>
                        <Route path="/login" component={AuthLogin} />
                        <Route path="/confirm-email" component={ConfirmEmail} />
                        <Route path="/reset-password" component={resetPassword} />
                        <Route path="/oops" component={UsedLink} />

                    </GuestGuard>
                </NavMotion>
            </Switch>
            {/* </MinimalLayout> */}
        </Route>
    );
};

export default LoginRoutes;
