import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/index')));


/////////////// sample Super Admin && Admin  /////////////////////


// Questions
const Questions = Loadable(lazy(() => import('../admin-view/Questions/Questions-list')));
const QuestionsEdit = Loadable(lazy(() => import('../admin-view/Questions/Questions-details')));
const Answer = Loadable(lazy(() => import('../admin-view/Questions/Answer-detail')));

// sample page cohort
const AdminPathList = Loadable(lazy(() => import('../admin-view/path/path-list')));
const AddCohort = Loadable(lazy(() => import('../admin-view/path/path-add')));
const EditCohort = Loadable(lazy(() => import('../admin-view/path/edit-path')));
const UserDetails = Loadable(lazy(() => import('../admin-view/path/user-details')));

// sample page path review
const REVIEWList = Loadable(lazy(() => import('../views/autre/reviews/review-list')));

// sample page lesson
const ADDCHAPTER = Loadable(lazy(() => import('../admin-view/path/CHAPITRES/chapitre-add')));
const EDITCHAPITRE = Loadable(lazy(() => import('../admin-view/path/CHAPITRES/chpitre-edit')));

// sample page lesson && submission
const LESSONS = Loadable(lazy(() => import('../views/lesson/lesson-list')));
const EDITLESSON = Loadable(lazy(() => import('../views/lesson/lesson-edit')));
const ADDLESSON = Loadable(lazy(() => import('../views/lesson/lesson-add')));
const Submission = Loadable(lazy(() => import('../views/submission/submission-list')));
const EditSubmission = Loadable(lazy(() => import('../views/submission/submission-edit')));


// sample page group
const GroupList = Loadable(lazy(() => import('../superadmin-view/group/group-list')));
const GroupAdd = Loadable(lazy(() => import('../superadmin-view/group/group-add')));
const GroupEdit = Loadable(lazy(() => import('../superadmin-view/group/group-edit')));

// sample page session
const AddSession = Loadable(lazy(() => import('../superadmin-view/session/sessions-add')));
const SessionList = Loadable(lazy(() => import('../superadmin-view/session/session-list')));
const SessionEdit = Loadable(lazy(() => import('../superadmin-view/session/session-edit')));
const SessionDetails = Loadable(lazy(() => import('../superadmin-view/session/session-details')));
const AffectUsers = Loadable(lazy(() => import('../superadmin-view/session/affect-users/index.js')));

// sample page admin
const AddAdmin = Loadable(lazy(() => import('../superadmin-view/admin-user/admin-add')));
const EditAdmin = Loadable(lazy(() => import('../superadmin-view/admin-user/admin-edit')));
const Admins = Loadable(lazy(() => import('../superadmin-view/admin-user/admins/index.js')));

// sample page instructor
const AdminInstructorList = Loadable(lazy(() => import('../admin-view/instructor/instructor-list')));
const AdminInstructorAdd = Loadable(lazy(() => import('../admin-view/instructor/instructor-add')));

// sample page student
const AdminStudentList = Loadable(lazy(() => import('../admin-view/student/student-list')));
const AdminStudentAdd = Loadable(lazy(() => import('../admin-view/student/student-add')));

// edit user
const EditUser = Loadable(lazy(() => import('../views/users/edit-user')));

// sample page partners
const Etablissement = Loadable(lazy(() => import('../admin-view/establishment/establishment-list')));
const EditEtablissement = Loadable(lazy(() => import('../admin-view/establishment/establishment-edit')));
const ADDEtablissement = Loadable(lazy(() => import('../admin-view/establishment/establishment-add')));
const AFFECTEtablissement = Loadable(lazy(() => import('../admin-view/establishment/affect-establishment')));

// sample page tag
const Categories = Loadable(lazy(() => import('../superadmin-view/categoy/category-list')));
const EditCategorie = Loadable(lazy(() => import('../superadmin-view/categoy/edit-category')));
const AddCategorie = Loadable(lazy(() => import('../superadmin-view/categoy/category-add')));

// sample page report
const AdminReportList = Loadable(lazy(() => import('../admin-view/report/report-list')));
const EditReport = Loadable(lazy(() => import('../admin-view/report/report-edit')));
const ReportDescription = Loadable(lazy(() => import('../admin-view/report/report-details')));

// sample page PreSelection
const PreSelectionList = Loadable(lazy(() => import('../superadmin-view/pre-selection/pre-selection-list')));
const PreSelectionDetails = Loadable(lazy(() => import('../superadmin-view/pre-selection/pre-selection-details')));
const PreSelectionReScheduled = Loadable(lazy(() => import('../superadmin-view/pre-selection/pre-selection-reSchedul.js')));
const PreSelectionPathList = Loadable(lazy(() => import('../superadmin-view/pre-selection/pre-selection-paths-list')));

// sample page Transactions
const Transactions = Loadable(lazy(() => import('../superadmin-view/transactions/transaction-list')));
const TransactionAdd = Loadable(lazy(() => import('../superadmin-view/transactions/transaction-add')));
const TransactionEdit = Loadable(lazy(() => import('../superadmin-view/transactions/transaction-edit')));


/////////////// sample instructor view  /////////////////////

// sample page Pending Assignments
const PendingAssignmentPathList = Loadable(lazy(() => import('../instructor-view/pending-assignments/path-list')));
const PendingAssignments = Loadable(lazy(() => import('../instructor-view/pending-assignments/pending-assignments-list')));
const PendingAssignmentsDetails = Loadable(lazy(() => import('../instructor-view/pending-assignments/pending-assignments-details')));

// sample page Instructor Students
const InstructorStudents = Loadable(lazy(() => import('../instructor-view/students/students-list')));
const UploadSignature = Loadable(lazy(() => import('../instructor-view/upload-signature')));
const StudentSession = Loadable(lazy(() => import('../instructor-view/students/students-session')));

const InstructorPath = Loadable(lazy(() => import('../instructor-view/instructor-path/path-list')));
const InstructorPathEdit = Loadable(lazy(() => import('../instructor-view/instructor-path/edit-path')));

// announcement
const Announcement = Loadable(lazy(() => import('../superadmin-view/announcement/announcement-add')));


// other
const CookiePolicy = Loadable(lazy(() => import('../superadmin-view/others/cookiePolicy')));
const HonorCode = Loadable(lazy(() => import('../superadmin-view/others/honerCode')));
const PrivacyPolicy = Loadable(lazy(() => import('../superadmin-view/others/privacyPolicy')));
const TermAndConditions = Loadable(lazy(() => import('../superadmin-view/others/termsAndConditions')));

const Profile = Loadable(lazy(() => import('../admin-view/profile/profile-form')));


//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    // Define your permissions
    const storedVariable = localStorage.getItem('Lantern-account');
    let userPermissions = JSON.parse(storedVariable).role


    return (
        <Route
            path={[
                '/dashboard/default',
                '/edit-user',
                '/partners',
                '/add-partner',
                '/edit-partner/:id',

                '/affect-establissement/:id',
                '/report-edite/:id',
                '/report-details/:id',

                '/admin-instructors',
                '/admin-instructors-add',
                '/admin-students',
                '/admin-students-add',
                '/admin-reports',
                '/profile',

                /////////////////////////////////////

                '/admins',
                '/admin-tag-list',
                '/add-tag',
                '/edit-tag/:id',
                '/superadmin-admin-add',
                '/superadmin-admin-edit/:id',
                '/admin-session-add',
                '/admin-session-list',
                '/edit-session/:id',
                '/session-details/:name/:id/:pathId',
                '/affect-users/:name/:id/:pathId',
                '/details/:id/:userId',
                '/pre-selection-path-list',
                '/pre-selection/:id',
                '/pre-selection-details/:id',
                '/pre-selection-reschedul/:id',

                '/announcement',

                '/admin-groups',
                '/admin-groups-add',
                '/admin-groups-edit/:id',

                '/admin-paths',
                '/add-path',
                '/edit-path/:id',

                '/add-chapter/:id',
                '/edit-chapter/:id',

                '/lessons',
                '/edit-lesson/:id',
                '/add-lesson/:id',
                '/submission',
                '/edit-submission/:id',
                '/questions',
                '/question-edit/:id',
                '/answer/:id',


                '/cookie-policy',
                '/honor-code',
                '/privacy-policy',
                '/terms-and-conditions',

                //////////////////////////////////////////
                '/assignment-path-list',
                '/pending-assignments-to-be-corrected/:id',
                '/pending-assignments-to-be-checked/:id',
                '/instructor-students',
                '/upload-signature',
                '/student-session/:id',
                '/transactions',
                '/transaction-add',
                '/transaction-edit/:id',
                '/instructor-paths',
                '/instructor-paths-edit/:id'

            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        {(userPermissions == 4 || userPermissions == 1 || userPermissions == 2) &&
                            <>
                                <Route path="/dashboard/default" component={DashboardDefault} />
                                <Route path="/profile" component={Profile} />
                                <Route path="/assignment-path-list" component={PendingAssignmentPathList} />
                                <Route path="/pending-assignments-to-be-corrected/:id" component={PendingAssignments} />
                                <Route path="/pending-assignments-to-be-checked/:id" component={PendingAssignmentsDetails} />
                                <Route path="/add-chapter/:id" component={ADDCHAPTER} />
                                <Route path="/edit-chapter/:id" component={EDITCHAPITRE} />
                                <Route path="/edit-lesson/:id" component={EDITLESSON} />
                                <Route path="/add-lesson/:id" component={ADDLESSON} />
                            </>
                        }

                        {userPermissions == 4 &&

                            <>
                                <Route path="/admins" component={Admins} />
                                <Route path="/superadmin-admin-add" component={AddAdmin} />
                                <Route path="/superadmin-admin-edit/:id" component={EditAdmin} />
                            </>
                        }

                        {(userPermissions == 4 || userPermissions == 1) &&
                            <>
                                <Route path="/admin-paths" component={AdminPathList} />
                                <Route path="/add-path" component={AddCohort} />
                                <Route path="/edit-path/:id" component={EditCohort} />
                               
                                <Route path="/admin-tag-list" component={Categories} />
                                <Route path="/add-tag" component={AddCategorie} />
                                <Route path="/edit-tag/:id" component={EditCategorie} />

                                <Route path="/admin-session-add" component={AddSession} />
                                <Route path="/admin-session-list" component={SessionList} />
                                <Route path="/edit-session/:id" component={SessionEdit} />
                                <Route path="/admin-groups" component={GroupList} />
                                <Route path="/admin-groups-add" component={GroupAdd} />
                                <Route path="/admin-groups-edit/:id" component={GroupEdit} />
                                <Route path="/session-details/:name/:id/:pathId" component={SessionDetails} />
                                <Route path="/details/:id/:userId" component={UserDetails} />
                                <Route path="/affect-users/:name/:id/:pathId" component={AffectUsers} />
                                <Route path="/pre-selection-path-list" component={PreSelectionPathList} />
                                <Route path="/pre-selection/:id"  component={PreSelectionList} />
                                <Route path="/pre-selection-details/:id" component={PreSelectionDetails} />
                                <Route path="/pre-selection-reschedul/:id" component={PreSelectionReScheduled} />
                                <Route path="/transactions" component={Transactions} />
                                <Route path="/transaction-add" component={TransactionAdd} />
                                <Route path="/transaction-edit/:id" component={TransactionEdit} />
                                <Route path="/announcement" component={Announcement} />
                                <Route path="/questions" component={Questions} />
                                <Route path="/question-edit/:id" component={QuestionsEdit} />
                                <Route path="/answer/:id" component={Answer} />
                                <Route path="/cookie-policy" component={CookiePolicy} />
                                <Route path="/honor-code" component={HonorCode} />
                                <Route path="/privacy-policy" component={PrivacyPolicy} />
                                <Route path="/terms-and-conditions" component={TermAndConditions} />


                                {/* ////////////////////////////////////////////////////////// */}


                                <Route path="/edit-user/:id" component={EditUser} />
                                <Route path="/partners" component={Etablissement} />
                                <Route path="/add-partner" component={ADDEtablissement} />
                                <Route path="/edit-partner/:id" component={EditEtablissement} />
                                <Route path="/affect-establissement/:id" component={AFFECTEtablissement} />
                                <Route path="/submission" component={Submission} />
                                <Route path="/edit-submission/:id" component={EditSubmission} />
                                <Route path="/report-edite/:id" component={EditReport} />
                                <Route path="/report-details/:id" component={ReportDescription} />
                                <Route path="/admin-instructors" component={AdminInstructorList} />
                                <Route path="/admin-instructors-add" component={AdminInstructorAdd} />
                                <Route path="/admin-students" component={AdminStudentList} />
                                <Route path="/admin-students-add" component={AdminStudentAdd} />
                                <Route path="/admin-reports" component={AdminReportList} />
                            </>
                        }

                        {userPermissions == 2 &&
                            <>
                                <Route path="/instructor-students" component={InstructorStudents} />
                                <Route path="/upload-signature" component={UploadSignature} />
                                <Route path="/student-session/:id" component={StudentSession} />
                                <Route path="/instructor-paths" component={InstructorPath} />
                                <Route path="/instructor-paths-edit/:id" component={InstructorPathEdit} />
                            </>
                        }
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
