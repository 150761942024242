import { dashboard } from './dashboard';
import { utilities } from './utilities';
import { others } from './other';
import { pages } from './pages';
import { instructor } from './instructor';
import { admin } from './admin';
import { superadmin } from './superadmin';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    // items: [dashboard, others, pages]
    items: [superadmin,pages]
};

export default menuItems;
